import { call, put, takeEvery } from "redux-saga/effects";
import { changePasswordResendOTPFail, changePasswordResendOTPPending, changePasswordResendOTPSuccess } from "../slice/changePasswordOTPResendSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.ROP_CHANGE_PASSWORD_OTP_RESEND_API;
const resendOTPData = async () => {
  const headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website"
    }
  };
  const response = await api.get(API_URL, headerData);
  sessionStorage.setItem("refNumber", response.data.otpRefKey);
  return response;
};
function* changePasswordResendOTPSaga(action) {
  try {
    const response = yield call(resendOTPData);
    yield put(changePasswordResendOTPSuccess(response.data));
  } catch (error) {
    yield put(changePasswordResendOTPFail(error));
  }
}
export function* watchChangePasswordResendOTP() {
  yield takeEvery(changePasswordResendOTPPending.type, changePasswordResendOTPSaga);
}