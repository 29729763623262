import { createSlice } from "@reduxjs/toolkit";
// import { FlightListsDataState } from "../models/models";

const initialState = {
  airAwardPriceInfo: {},
  isLoading: false,
  hasData: false,
  error: ""
};
const airAwardPriceSlice = createSlice({
  name: "airAwardPrice",
  initialState,
  reducers: {
    airAwardPriceRequest: state => {
      state.isLoading = true;
    },
    airAwardPriceSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardPriceInfo = action.payload;
    },
    airAwardPriceFailure: (state, action) => {
      state.isLoading = false;
      if (action.payload.data.data[0].errorMsg) {
        state.error = action.payload.data.data[0].errorMsg[0];
      } else {
        state.error = action.payload;
      }
    },
    airAwardPriceReset: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardPriceInfo = {};
    }
  }
});
export const {
  airAwardPriceRequest,
  airAwardPriceSuccess,
  airAwardPriceFailure,
  airAwardPriceReset
} = airAwardPriceSlice.actions;
export default airAwardPriceSlice.reducer;