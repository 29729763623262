import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  changePasswordDetails: [],
  error: {},
  isLoading: false,
  hasData: false
};
const crmChangePasswordSlice = createSlice({
  name: "crmChangePassword",
  initialState,
  reducers: {
    crmChangePasswordDataRequest: (state, action) => {
      state.isLoading = true;
      state.error = "";
    },
    crmChangePasswordDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.changePasswordDetails = action.payload;
    },
    crmChangePasswordDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    crmClearOtpRefKeyResponse: state => {
      state.changePasswordDetails = [];
    }
  }
});
export const {
  crmChangePasswordDataRequest,
  crmChangePasswordDataSuccess,
  crmChangePasswordDataFailure,
  crmClearOtpRefKeyResponse
} = crmChangePasswordSlice.actions;
export default crmChangePasswordSlice.reducer;