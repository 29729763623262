import { combineReducers } from "@reduxjs/toolkit";
import checkInSlice from "./checkin/checkinSlice";
import addPassengerSlice from "./checkin/addPassengerSlice";
import cancelCheckin from "./cancelCheckin/cancelCheckinSlice";
import cancelRetrieveCheckinSlice from "./cancelCheckin/cancelRetrieveJourneySlice";
import removeSelectPassengerCheckinSlice from "./checkin/removeSelectPassengerSlice";
import selectPassengerSlice from "./checkin/selectPassengerDetailsSlice";
import fetchFrequentFlyerProgramSlice from "./checkin/passengerDetails/frequentFlyerProgramSlice";
import updatedPassengerSlice from "./checkin/updatePassengerDetailsSlice";
import nationalityCountrySlice from "./checkin/passengerDetails/nationalityCountrySlice";
import fetchAutoSeatSlice from "./checkin/autoSeatSlice";
import seatMapSlice from "./seatMap/seatMapSlice";
import fetchCheckinBoardingPassSlice from "./checkin/checkinBoardingPassSlice";
const RootReducer = combineReducers({
  checkin: checkInSlice,
  addPassenger: addPassengerSlice,
  cancelCheckin: cancelCheckin,
  cancelRetreiveCheckin: cancelRetrieveCheckinSlice,
  removeSelectPassenger: removeSelectPassengerCheckinSlice,
  selectPassenger: selectPassengerSlice,
  frequentFlyerProgram: fetchFrequentFlyerProgramSlice,
  updatePassenger: updatedPassengerSlice,
  nationalityCountry: nationalityCountrySlice,
  autoSeat: fetchAutoSeatSlice,
  seatMapResponse: seatMapSlice,
  checkinBoardingPass: fetchCheckinBoardingPassSlice
});
export default RootReducer;