import axios from "axios";
//process.env variable to be added
//apiconfig/ apiconstants to be added

//ToDo:This can be uncommented once the CMS supports all country lang codes.
// Now only handling english and thai

//const countryLanguageCode: string = i18n.language || 'en-th';
const countryLanguageCode = 'en-th';
const base_urls = {
  teamsite: process.env.REACT_APP_TG_TEAMSITE_URL + 'en-th',
  aemContentAuthored: process.env.REACT_APP_TG_AEM_BASE_URL + countryLanguageCode,
  aemConfigured: process.env.REACT_APP_TG_AEM_BASE_URL + "dam/" + countryLanguageCode,
  aemServlet: process.env.REACT_APP_TG_AEM_SERVLET_URL
};
const cmsApi = axios.create();

// Request interceptor
cmsApi.interceptors.request.use(config => {
  // Modify the request config here (e.g., add headers, authentication tokens)
  console.log("conf", config);
  config.baseURL = base_urls === null || base_urls === void 0 ? void 0 : base_urls[config === null || config === void 0 ? void 0 : config.engine];
  return config;
}, error => {
  // Handle request errors here

  return Promise.reject(error);
});

// Response interceptor
cmsApi.interceptors.response.use(response => {
  // Modify the response data here (e.g., parse, transform)

  return response;
}, error => {
  // Handle response errors here

  return Promise.reject(error);
});
export default cmsApi;