import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  crmSignupDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  crmStatus: {
    status: false,
    message: ""
  },
  crmSignupSocialMediaDetails: {},
  crmSignupSocialMediaErrors: "",
  crmSignupSocialMediaLoading: false
};
const crmSignupSlice = createSlice({
  name: "crmSignup",
  initialState,
  reducers: {
    getCrmSignUpDataPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getCrmSignUpSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.crmSignupDetails = action.payload;
      if (state.crmSignupDetails.statusMessage === "Success") {
        state.crmStatus.message = "Member Added";
        state.crmStatus.status = true;
        sessionStorage.setItem("email", action.payload.email);
      }
      state.codeStatus = "success";
    },
    getCrmSignupFailure: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.crmSignupDetails = action.payload.response.data;
      if (action.payload.response.data.code === "999") {
        if (action.payload.response.data.message.includes("This Email is already associated")) {
          state.crmStatus.message = "Duplicate";
          state.crmStatus.status = false;
        } else {
          state.crmStatus.message = "GenericError";
          state.crmStatus.status = false;
        }
      }
    },
    updateSignInState: (state, action) => {
      const {
        updatedState
      } = action.payload;
      state.isSignin = updatedState;
    },
    clearCrmSignUpResponse: state => {
      state.crmStatus = {
        status: false,
        message: ""
      };
    },
    getCrmSignUpSocialMediaDataPending: (state, action) => {
      state.crmSignupSocialMediaLoading = true;
      state.codeStatus = "pending";
    },
    getCrmSignUpocialMediaSuccess: (state, action) => {
      state.crmSignupSocialMediaLoading = false;
      state.crmSignupSocialMediaDetails = action.payload;
      if (state.crmSignupSocialMediaDetails.success) {
        state.crmStatus.message = "Member Added";
        state.crmStatus.status = true;
        sessionStorage.setItem("email", action.payload.email);
      } else if (state.crmSignupSocialMediaDetails.response.data.Status === 400) {
        if (state.crmSignupSocialMediaDetails.response.data.Message.includes("already exists.")) {
          state.crmStatus.message = "Duplicate";
          state.crmStatus.status = false;
        } else {
          state.crmStatus.message = "GenericError";
          state.crmStatus.status = false;
        }
      } else {
        state.crmStatus.message = "GenericError";
        state.crmStatus.status = false;
      }
      state.codeStatus = "success";
    },
    getCrmSignUpSocialMediaFailure: (state, action) => {
      state.crmSignupSocialMediaLoading = false;
      state.codeStatus = "failure";
      state.crmSignupSocialMediaDetails = action.payload;
      //Error handling
    }
  }
});
export const {
  getCrmSignUpDataPending,
  getCrmSignupFailure,
  getCrmSignUpSuccess,
  updateSignInState,
  clearCrmSignUpResponse,
  getCrmSignUpSocialMediaDataPending,
  getCrmSignUpocialMediaSuccess,
  getCrmSignUpSocialMediaFailure
} = crmSignupSlice.actions;
export default crmSignupSlice.reducer;