import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: {},
  paxInfo: [],
  flightInfo: [],
  isLoading: false,
  error: "",
  hasData: false
};
const flightFareSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    flightFareRequest: state => {
      state.isLoading = true;
    },
    flightFareSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.data = action.payload;
    },
    flightFareFail: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.error = action.payload;
      state.data = {};
    },
    resetFlightFare: state => {
      state.data = {};
      state.error = "";
      state.hasData = false;
    }
  }
});
export const {
  flightFareRequest,
  flightFareSuccess,
  flightFareFail,
  resetFlightFare
} = flightFareSlice.actions;
export default flightFareSlice.reducer;