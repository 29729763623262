import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: {},
  bookingReport: {},
  bookingReportLoading: false,
  bookingReportError: "",
  isLoading: false,
  error: "",
  hasData: false
};
const airAwardConfirmationSlice = createSlice({
  name: "airAwardConfirmationData",
  initialState,
  reducers: {
    airAwardConfirmationRequest: (state, action) => {
      state.isLoading = true;
    },
    airAwardConfirmationSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.data = action.payload;
    },
    airAwardConfirmationFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardConfirmationReset: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.data = {};
    },
    airAwardDownloadRequest: (state, action) => {
      state.bookingReportLoading = true;
    },
    airAwardDownloadSuccess: (state, action) => {
      state.bookingReportLoading = false;
      state.bookingReport = action.payload;
    },
    airAwardDownloadFailure: (state, action) => {
      state.bookingReportLoading = false;
      state.bookingReportError = action.payload;
    }
  }
});
export const {
  airAwardConfirmationRequest,
  airAwardConfirmationSuccess,
  airAwardConfirmationFail,
  airAwardConfirmationReset,
  airAwardDownloadRequest,
  airAwardDownloadSuccess,
  airAwardDownloadFailure
} = airAwardConfirmationSlice.actions;
export default airAwardConfirmationSlice.reducer;