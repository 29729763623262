import { call, put, takeLatest } from "redux-saga/effects";
import { boardingPassDataRequest, getBoardingPassDataSuccess, getBoardingPassDataFailure } from "../slice/boardingPassSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const headerData = {
  mode: "no-cors",
  headers: {
    Authorization: localStorage.getItem("accesstoken"),
    "Content-Type": "application/json"
  },
  loader: true
};
const fetchBoardingPassData = async payload => {
  const BOARDING_PASS_API_URL = config.BOARDING_PASS;
  let payloadData = {
    tokenRequired: true,
    closeSession: true,
    boardingPassIds: payload
  };
  const response = await api.post(BOARDING_PASS_API_URL, payloadData, headerData);
  return response;
};
function* getBoardingPassData(action) {
  const {
    payload
  } = action;
  try {
    var _response$data;
    const response = yield call(fetchBoardingPassData, payload);
    yield put(getBoardingPassDataSuccess(response === null || response === void 0 || (_response$data = response.data) === null || _response$data === void 0 || (_response$data = _response$data.response) === null || _response$data === void 0 ? void 0 : _response$data.boardingPassData));
  } catch (error) {
    yield put(getBoardingPassDataFailure(error === null || error === void 0 ? void 0 : error.response));
  }
}
export function* watchBoardingPassData() {
  yield takeLatest(boardingPassDataRequest.type, getBoardingPassData);
}