import axios from "axios";
import { store } from "../store";
import { setError } from "../OSCI/src/slice/errorModalSlice";
import i18n from "../i18n";
//process.env variable to be added
//apiconfig/ apiconstants to be added
const api = axios.create({
  baseURL: process.env.REACT_APP_TG_API_BASE_URL
});
let numberOfAjaxCAllPending = 0;

// Request interceptor
api.interceptors.request.use(config => {
  var _window, _window2, _window$location;
  // Modify the request config here (e.g., add headers, authentication tokens)
  //const accessToken:any = JSON.parse(sessionStorage.getItem("token") || '{}') ;
  // ** If token is present add it to request's Authorization Header
  // if (Object.keys(accessToken).length > 0) {
  //   if (config.headers) {
  //     config.headers.token = `Bearer ${accessToken}`;
  //   }
  // }
  if (config !== null && config !== void 0 && config.loader) {
    numberOfAjaxCAllPending++;
    document.documentElement.style.setProperty("--loading-spinner", "block");
  }
  if (config.headers) {
    config.headers.source = "website";
    config.headers.hostName = window.location.origin;
  }
  const authToken = typeof window !== "undefined" && ((_window = window) === null || _window === void 0 || (_window = _window.localStorage) === null || _window === void 0 ? void 0 : _window.getItem("accesstoken"));
  if (authToken) {
    config.headers.Authorization = authToken;
  }
  const checkInAuthToken = typeof window !== "undefined" && ((_window2 = window) === null || _window2 === void 0 || (_window2 = _window2.sessionStorage) === null || _window2 === void 0 ? void 0 : _window2.getItem("checkintoken"));
  if (checkInAuthToken && (_window$location = window.location) !== null && _window$location !== void 0 && (_window$location = _window$location.pathname) !== null && _window$location !== void 0 && (_window$location = _window$location.toLowerCase()) !== null && _window$location !== void 0 && _window$location.includes("checkin")) {
    config.headers.AccessToken = checkInAuthToken;
  }
  return config;
}, error => {
  // Handle request errors here

  return Promise.reject(error);
});

// Response interceptor
api.interceptors.response.use(response => {
  var _response$config;
  // Modify the response data here (e.g., parse, transform)
  if (response !== null && response !== void 0 && (_response$config = response.config) !== null && _response$config !== void 0 && _response$config.loader) {
    numberOfAjaxCAllPending--;
  }
  if (numberOfAjaxCAllPending === 0) {
    document.documentElement.style.setProperty("--loading-spinner", "none");
  }

  // document.documentElement.style.setProperty("--loading-spinner", "none");
  return response;
}, error => {
  var _error$config, _error$config2;
  if (error !== null && error !== void 0 && (_error$config = error.config) !== null && _error$config !== void 0 && _error$config.isGenericErr) {
    var _error$response;
    const errorStatus = (error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) || (error === null || error === void 0 ? void 0 : error.status);
    // Handle response errors here
    if (error.code === "ERR_NETWORK") {
      store.dispatch(setError({
        message: i18n.t("error_network_failed"),
        heading: "Network Error",
        buttonText: i18n.t("button_too_many_codes_ok")
      }));
    } else if (errorStatus === 401) {
      // Handle unauthorised scenario
      store.dispatch(setError({
        message: i18n.t("error_unauthorised"),
        heading: i18n.t("error_login_session_expired"),
        buttonText: i18n.t("label_enroll_sign_in"),
        errCode: 401,
        isLoginReq: true
      }));
    } else if (errorStatus === 500 || errorStatus === 501 || errorStatus === 502 || errorStatus === 503 || errorStatus === 504) {
      store.dispatch(setError({
        message: i18n.t("error_request_failed"),
        heading: "Server Error",
        buttonText: i18n.t("button_too_many_codes_ok")
      }));
    } else if (errorStatus === 403 || errorStatus === 404 || errorStatus === 405 || errorStatus === 408 || errorStatus === 429) {
      store.dispatch(setError({
        message: i18n.t("error_request_failed"),
        heading: "Client Error",
        buttonText: i18n.t("button_too_many_codes_ok")
      }));
    }
  }
  if (error !== null && error !== void 0 && (_error$config2 = error.config) !== null && _error$config2 !== void 0 && _error$config2.loader) {
    numberOfAjaxCAllPending--;
  }
  if (numberOfAjaxCAllPending === 0) {
    document.documentElement.style.setProperty("--loading-spinner", "none");
  }
  return Promise.reject(error);
});
export default api;