import { call, put, takeEvery } from "redux-saga/effects";
import { crmChangePasswordDataRequest, crmChangePasswordDataSuccess, crmChangePasswordDataFailure } from "../slice/crmChangePasswordSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.CRM_CHANGE_PASSWORD;
const fetchCRMChangePin = async changePinPayload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: localStorage.getItem("accesstoken")
    },
    isGenericErr: false
  };
  let changePinPayloadData = {
    oldPin: changePinPayload.oldPin,
    newPin: changePinPayload.newPin
  };
  const response = await api.post(API_URL, changePinPayloadData, data);
  if (response) {
    sessionStorage.setItem("refNumber", response === null || response === void 0 ? void 0 : response.data.otpRefKey);
    sessionStorage.setItem("newPin", changePinPayload.newPin);
  }
  return response;
};
function* fetchCRMChangePinSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchCRMChangePin, payload);
    yield put(crmChangePasswordDataSuccess({
      ...response
    }));
  } catch (error) {
    yield put(crmChangePasswordDataFailure({
      ...error
    }));
  }
}
export function* watchCRMChangePin() {
  yield takeEvery(crmChangePasswordDataRequest.type, fetchCRMChangePinSaga);
}