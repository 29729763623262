import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  hasData: false,
  baggageData: {},
  barcode: {},
  error: "",
  isError: false,
  baggageSearchInput: {}
};
const baggageTrackingSlice = createSlice({
  name: "baggageTrackingSlice",
  initialState,
  reducers: {
    barcodeRequest: (state, action) => {
      state.isLoading = true;
    },
    barcodeSuccess: (state, action) => {
      state.isLoading = false;
      state.barcode = action.payload;
      state.isError = false;
      state.error = "";
    },
    barcodeFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    baggageRequest: (state, action) => {
      state.isLoading = true;
    },
    baggageSuccess: (state, action) => {
      state.isLoading = false;
      state.baggageData = action.payload;
      state.isError = false;
      state.error = "";
    },
    baggageFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    baggageRequestMyTrips: (state, action) => {
      state.isLoading = true;
    },
    saveBaggageInputDetails: (state, action) => {
      state.baggageSearchInput = action.payload;
    }
  }
});
export const {
  barcodeRequest,
  barcodeSuccess,
  barcodeFailure,
  baggageRequest,
  baggageRequestMyTrips,
  baggageSuccess,
  baggageFailure,
  saveBaggageInputDetails
} = baggageTrackingSlice.actions;
export default baggageTrackingSlice.reducer;