import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  seatDetails: {},
  isLoading: false,
  hasData: false,
  error: "",
  seatStatus: {
    status: false,
    message: ""
  }
};
const fetchAutoSeatSlice = createSlice({
  name: "autoSeat",
  initialState,
  reducers: {
    retrieveAutoSeatRequest: (state, action) => {
      state.isLoading = true;
      state.seatStatus.status = false;
      state.error = "";
    },
    retrieveAutoSeatSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.seatStatus.status = true;
      state.seatDetails = action.payload;
    },
    retrieveAutoSeatFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.errors;
      state.seatStatus.status = false;
      state.seatDetails = {};
    },
    resetAutoSeatDetails: state => {
      state.isLoading = false;
      state.seatDetails = {};
      state.seatStatus.status = false;
      state.hasData = false;
      state.error = "";
    }
  }
});
export const {
  retrieveAutoSeatRequest,
  retrieveAutoSeatSuccess,
  retrieveAutoSeatFailure,
  resetAutoSeatDetails
} = fetchAutoSeatSlice.actions;
export default fetchAutoSeatSlice.reducer;