import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  boardingPassDetails: [],
  isLoading: false,
  error: ""
};
const fetchCheckinBoardingPassSlice = createSlice({
  name: "checkinBoardingPass",
  initialState,
  reducers: {
    checkinBoardingPassDataRequest: state => {
      state.isLoading = true;
    },
    getCheckinBoardingPassSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.boardingPassDetails = action.payload.data;
    },
    getCheckinBoardingPassFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.boardingPassDetails = [];
    }
  }
});
export const {
  checkinBoardingPassDataRequest,
  getCheckinBoardingPassSuccess,
  getCheckinBoardingPassFailure
} = fetchCheckinBoardingPassSlice.actions;
export default fetchCheckinBoardingPassSlice.reducer;