import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  promotionDetails: [],
  isLoading: false,
  error: "",
  hasData: false
};
const primaryCarouselSlice = createSlice({
  name: "primaryCarousel",
  initialState,
  reducers: {
    getPromotionDataRequest: (state, action) => {
      state.isLoading = true;
    },
    getPromotionDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.promotionDetails = action.payload;
    },
    getPromotionDataFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getPromotionDataRequest,
  getPromotionDataSuccess,
  getPromotionDataFail
} = primaryCarouselSlice.actions;
export default primaryCarouselSlice.reducer;