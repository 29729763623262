import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  memberId: null,
  bookingEmail: null,
  pnr: null,
  flightNumber: null,
  flightDate: null,
  ticketNumber: null,
  description: null,
  multipleRequest: null,
  secondTopic: null,
  secondDescription: null,
  portalCaseTopic: null,
  portalCaseSubTopic: null,
  savedMyThaiCategory: {},
  successResponse: {},
  formStatus: {
    status: false,
    message: ""
  },
  isLoading: false,
  isError: false,
  error: false
};
const crmServiceRequestSlice = createSlice({
  name: "crmRequestService",
  initialState,
  reducers: {
    crmServiceRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    crmServiceRequestSuccess: (state, action) => {
      state.savedMyThaiCategory = action.payload;
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successResponse = action.payload;
    },
    crmServiceRequestFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    },
    clearFormSuccessResponse: state => {
      state.formStatus.message = "";
      state.formStatus.status = false;
    },
    crmServiceRequestInputSave: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    crmServiceRequestClear: state => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
      state.savedMyThaiCategory = null;
    },
    submitFormSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successData = action.payload;
      if (state.successData.message === "Case Created") {
        state.formStatus.message = "Case Created";
        state.formStatus.status = true;
      }
      // state.codeStatus = "success";
    }
  }
});
export const {
  crmServiceRequest,
  crmServiceRequestSuccess,
  crmServiceRequestFailure,
  crmServiceRequestInputSave,
  crmServiceRequestClear,
  submitFormSuccess,
  clearFormSuccessResponse
} = crmServiceRequestSlice.actions;
export default crmServiceRequestSlice.reducer;