import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  removeSelectPassengerInfo: {},
  isLoading: false,
  hasData: false,
  error: "",
  removeselectPassengerStatus: {
    status: false,
    message: ""
  }
};
const removeSelectPassengerSlice = createSlice({
  name: "removeSelectPassenger",
  initialState,
  reducers: {
    removeSelectPassengerRequest: (state, action) => {
      state.isLoading = true;
    },
    removeSelectPassengerSuccess: (state, action) => {
      var _action$payload;
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.removeSelectPassengerInfo = action.payload;
      state.removeselectPassengerStatus.status = true;
      state.removeselectPassengerStatus.message = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.message;
    },
    removeSelectPassengerFailure: (state, action) => {
      var _action$payload2;
      console.log("remove select passenger line 28");
      state.isLoading = false;
      state.error = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 || (_action$payload2 = _action$payload2.response) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data;
      state.removeSelectPassengerInfo = {};
      state.removeselectPassengerStatus.status = false;
      state.removeselectPassengerStatus.message = "";
    },
    resetRemoveSelectedPassengers: state => {
      state.removeSelectPassengerInfo = {};
      state.isLoading = false;
      state.hasData = false;
      state.error = "";
      state.removeselectPassengerStatus.status = false;
      state.removeselectPassengerStatus.message = "";
    }
  }
});
export const {
  removeSelectPassengerRequest,
  removeSelectPassengerSuccess,
  removeSelectPassengerFailure,
  resetRemoveSelectedPassengers
} = removeSelectPassengerSlice.actions;
export default removeSelectPassengerSlice.reducer;