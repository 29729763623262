import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  flightInfoDetails: {},
  minMilesObj: {},
  flightInfoResponse: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
    5: {}
  },
  isLoading: false,
  error: "",
  hasData: false,
  flightInfoArr: [],
  displayContinueButton: true,
  selectedFlightList: [],
  redemptionBookingData: [],
  redemptionBookingDates: [],
  selectedRuleSet: "",
  selectedFlightIndex: [],
  disableSearchFlightButton: {
    isEdit: false,
    disable: true,
    toggled: false
  },
  clearSelectedFlight: {
    from: false,
    to: false,
    fromValue: {},
    toValue: {}
  },
  maxPassenger: null,
  isGroupByFlagSelected: false,
  flightFareRequestData: [],
  selectedClassIndex: null
};
const flightInfoSlice = createSlice({
  name: "flightInfo",
  initialState,
  reducers: {
    flightInfoRequest: state => {
      state.isLoading = true;
    },
    flightInfoSuccess: (state, action) => {
      var _action$payload;
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.flightInfoDetails = action.payload;
      state.flightInfoResponse[action.payload.id] = action.payload;
      // state.minMilesObj[action.payload.id] = action.payload?.data?.flightList[0]?.classList[0]?.miles;
      state.minMilesObj[action.payload.id] = (_action$payload = action.payload) === null || _action$payload === void 0 || (_action$payload = _action$payload.data) === null || _action$payload === void 0 ? void 0 : _action$payload.lowestMiles;
      state.flightInfoArr = [...(state === null || state === void 0 ? void 0 : state.flightInfoArr), {
        ...action.payload,
        requestParam: {
          ...JSON.parse(action.payload.config.data),
          ...action.payload.moreInfo
        },
        id: action.payload.id
      }].sort((x, y) => x.id - y.id);
    },
    flightInfoFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.flightInfoDetails = {};
      state.flightInfoResponse[action.payload.id] = action.payload;
      state.minMilesObj = {};
      state.flightInfoArr = [...(state === null || state === void 0 ? void 0 : state.flightInfoArr), {
        ...action.payload,
        requestParam: {
          ...JSON.parse(action.payload.config.data),
          ...action.payload.moreInfo
        },
        id: action.payload.id
      }].sort((x, y) => x.id - y.id);
    },
    clearFlightInfoResponse: (state, action) => {
      state.flightInfoResponse[action.payload.id] = {};
    },
    clearFlightInfoData: (state, action) => {
      state.flightInfoArr = [];
      state.flightInfoDetails = {};
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.minMilesObj = {};
    },
    clearFlightInfoDatawithId: (state, action) => {
      let filteredArr = state.flightInfoArr.filter(x => x.id !== action.payload.id);
      state.flightInfoArr = filteredArr;
    },
    disable_continue_btn: (state, action) => {
      state.displayContinueButton = action.payload;
    },
    selected_flightInfo_data: (state, action) => {
      state.selectedFlightList = action.payload;
    },
    clearSelectedFlightInfoData: state => {
      state.selectedFlightList = [];
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
    },
    saveRuleSetId: (state, action) => {
      state.selectedRuleSet = action.payload;
    },
    saveRedemptionBookingData: (state, action) => {
      state.redemptionBookingData = action.payload;
    },
    saveRedemptionBookingDates: (state, action) => {
      state.redemptionBookingDates = action.payload;
    },
    updateRedemptionBookingDates: (state, action) => {
      var _state$redemptionBook;
      state.redemptionBookingDates = (_state$redemptionBook = state.redemptionBookingDates) === null || _state$redemptionBook === void 0 ? void 0 : _state$redemptionBook.map((val, index) => index.toString() === action.payload.id.toString() ? action.payload.updatedDate : val);
    },
    selected_flight_index: (state, action) => {
      state.selectedFlightIndex = action.payload;
    },
    clearSelectedFlightIndex: state => {
      state.selectedFlightIndex = [];
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
    },
    removeLastTwoSelectedFlightIndex: state => {
      state.selectedFlightIndex = state.selectedFlightIndex.slice(0, -2);
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
    },
    resetRedemeptionBookingData: state => {
      state.redemptionBookingData = [];
    },
    updateSearchFlightButton: (state, action) => {
      state.disableSearchFlightButton = action.payload;
    },
    resetEditSelection: state => {
      state.disableSearchFlightButton.isEdit = false;
    },
    updateDisableButton: (state, action) => {
      state.disableSearchFlightButton.disable = action.payload;
    },
    updateToggleState: (state, action) => {
      state.disableSearchFlightButton.toggled = action.payload;
    },
    updateClearButtonSelection: (state, action) => {
      state.clearSelectedFlight = action.payload;
    },
    updateMaxPaxCount: (state, action) => {
      state.maxPassenger = action.payload;
    },
    updateGroupByFlag: (state, action) => {
      state.isGroupByFlagSelected = action.payload;
    },
    updateFlightFareRequest: (state, action) => {
      const {
        index,
        object
      } = action.payload;
      state.flightFareRequestData = [...state.flightFareRequestData.filter(item => item.id !== index), ...object.map(item => ({
        ...item
      }))];
    },
    clearFlightFareRequest: state => {
      state.flightFareRequestData = [];
    },
    updateSelectedClassIndex: (state, action) => {
      state.selectedClassIndex = action.payload;
    },
    clearSelectedClassIndex: state => {
      state.selectedClassIndex = null;
    }
  }
});
export const {
  flightInfoRequest,
  flightInfoSuccess,
  flightInfoFail,
  clearFlightInfoResponse,
  clearFlightInfoData,
  clearFlightInfoDatawithId,
  disable_continue_btn,
  selected_flightInfo_data,
  saveRedemptionBookingData,
  saveRedemptionBookingDates,
  saveRuleSetId,
  updateRedemptionBookingDates,
  selected_flight_index,
  clearSelectedFlightIndex,
  removeLastTwoSelectedFlightIndex,
  clearSelectedFlightInfoData,
  resetRedemeptionBookingData,
  updateSearchFlightButton,
  resetEditSelection,
  updateDisableButton,
  updateToggleState,
  updateClearButtonSelection,
  updateMaxPaxCount,
  updateGroupByFlag,
  updateFlightFareRequest,
  clearFlightFareRequest,
  updateSelectedClassIndex,
  clearSelectedClassIndex
} = flightInfoSlice.actions;
export default flightInfoSlice.reducer;