import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  crmOTP: [],
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending"
};
const crmOTPSlice = createSlice({
  name: "crmotpSection",
  initialState,
  reducers: {
    sendCRMOTPPending: state => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    sendCRMOTPSuccess: (state, action) => {
      var _action$payload, _action$payload$profi, _action$payload$profi2, _action$payload2;
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmOTP = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem("customerID", action.payload.profileDetail.customerID);
      sessionStorage.setItem("FirstName", (_action$payload = action.payload) === null || _action$payload === void 0 || (_action$payload = _action$payload.profileDetail) === null || _action$payload === void 0 ? void 0 : _action$payload.firstName);
      localStorage.setItem("isCRMLoggedIn", JSON.stringify(true));
      localStorage.setItem("isROPLoggedIn", JSON.stringify(false));
      let fullName = "";
      fullName = ((_action$payload$profi = action.payload.profileDetail) === null || _action$payload$profi === void 0 ? void 0 : _action$payload$profi.salutation) + " " + ((_action$payload$profi2 = action.payload.profileDetail) === null || _action$payload$profi2 === void 0 ? void 0 : _action$payload$profi2.firstName) + " " + ((_action$payload2 = action.payload) === null || _action$payload2 === void 0 || (_action$payload2 = _action$payload2.profileDetail) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.lastName);
      sessionStorage.setItem("FullName", fullName);
    },
    sendCRMOTPFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
    },
    resetCRMOTPData: state => {
      state.codeStatus = "pending";
      state.error = "";
      state.crmOTP = [];
      state.hasData = false;
    }
  }
});
export const {
  sendCRMOTPPending,
  sendCRMOTPSuccess,
  sendCRMOTPFailure,
  resetCRMOTPData
} = crmOTPSlice.actions;
export default crmOTPSlice.reducer;