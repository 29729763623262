import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { searchByMilesRequest, searchByMilesSuccess, searchByMilesFailure } from "../slice/searchByMilesSlice";
const headerData = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  loader: true
};

//api handling for search by miles
const fetchSearchMiles = async payload => {
  const MILES_SEARCH_URL = config === null || config === void 0 ? void 0 : config.MILES_SEARCH;
  let payloadData = {
    origin: payload.origin,
    miles: payload.miles,
    passengers: payload.passengers
  };
  const response = await api.post(MILES_SEARCH_URL, payloadData, headerData);
  return response;
};
function* getSearchMiles(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchSearchMiles, payload);
    yield put(searchByMilesSuccess(response === null || response === void 0 ? void 0 : response.data));
  } catch (error) {
    var _error$response;
    yield put(searchByMilesFailure(error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.data));
  }
}
export function* watchSearchMiles() {
  yield takeEvery(searchByMilesRequest.type, getSearchMiles);
}