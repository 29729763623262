import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  excessBaggageData: [],
  excessBaggageInfoData: {},
  isLoading: false,
  hasData: false,
  error: {},
  isError: false
};
const excessBaggageSlice = createSlice({
  name: "excessBaggage",
  initialState,
  reducers: {
    excessBaggageRequest: state => {
      state.isLoading = true;
    },
    excessBaggageSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = {};
      state.excessBaggageData = action.payload.data;
      state.isError = false;
    },
    excessBaggageFailure: (state, action) => {
      var _action$payload, _state$error, _state$error2;
      state.isLoading = false;
      state.error = (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data;
      state.excessBaggageData = [];
      console.log(action);
      if (((_state$error = state.error) === null || _state$error === void 0 ? void 0 : _state$error.status) == 400 || ((_state$error2 = state.error) === null || _state$error2 === void 0 ? void 0 : _state$error2.status) == 401) {
        state.isError = true;
      }
    },
    excess_baggage_info_data: (state, action) => {
      state.excessBaggageInfoData = action.payload;
    }
  }
});
export const {
  excessBaggageRequest,
  excessBaggageSuccess,
  excessBaggageFailure,
  excess_baggage_info_data
} = excessBaggageSlice.actions;
export default excessBaggageSlice.reducer;