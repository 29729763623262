import { call, put, takeEvery } from 'redux-saga/effects';
import { getPromotionDataRequest, getPromotionDataFail, getPromotionDataSuccess } from '../../slice/promotionSlice';
import i18n from "i18next";
import { config } from '../../../../config/global';
import axios from "axios";
const data = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  body: {}
};
const currentCountryLang = (i18n === null || i18n === void 0 ? void 0 : i18n.language) || 'en-th';
const fetchPromotionData = async promotionDataPayload => {
  //const path = `/assets/${currentCountry}/${currentLanguage}/promotion.json`;
  const PROMOTION_URL = process.env.REACT_APP_TG_AEM_BASE_URL + currentCountryLang + config.CMS_BANNER_CAROUSEL_HOME;
  const response = await axios.get(PROMOTION_URL, data);
  return response;
};
function* fetchPromotionSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchPromotionData, payload);
    yield put(getPromotionDataSuccess(response.data));
  } catch (error) {
    yield put(getPromotionDataFail(error.message));
  }
}
export function* watchFetchPromotionData() {
  yield takeEvery(getPromotionDataRequest.type, fetchPromotionSaga);
}