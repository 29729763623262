import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  boardingPassDetails: null,
  isLoading: false,
  error: ""
};
const fetchBoardingPassSlice = createSlice({
  name: "boardingPass",
  initialState,
  reducers: {
    boardingPassDataRequest: (state, action) => {
      state.isLoading = true;
    },
    getBoardingPassDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.boardingPassDetails = action.payload;
    },
    getBoardingPassDataFailure: (state, action) => {
      state.isLoading = false;
      state.boardingPassDetails = [];
      state.error = action.payload;
    },
    clearBoardingPassDetails: state => {
      state.boardingPassDetails = null;
      state.isLoading = false;
      state.error = "";
    }
  }
});
export const {
  boardingPassDataRequest,
  getBoardingPassDataSuccess,
  getBoardingPassDataFailure,
  clearBoardingPassDetails
} = fetchBoardingPassSlice.actions;
export default fetchBoardingPassSlice.reducer;