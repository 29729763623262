import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import { all, call } from "redux-saga/effects";
import osciRootReducer from "./OSCI/src/slice/RootReducer";
import dapiRootReducer from "./DAPI/src/slice/RootReducer";
import { persistStore, persistReducer, PURGE, FLUSH } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import osciRootSaga from "./OSCI/src/saga/rootSaga";
import dapiRootSaga from "./DAPI/src/saga/rootSaga";
import { FETCH_DATA_CONSTANT } from "./OSCI/src/saga/apiSaga";
import { airAwardPnrReset } from "./OSCI/src/slice/airAwardPnrSlice";
import { airAwardReviewPnrRMReset } from "./OSCI/src/slice/airAwardReviewPnrRMSlice";
import { airAwardReviewPnrCancelReset } from "./OSCI/src/slice/airAwardReviewPnrCancelSlice";
import { retrivePNRReset } from "./OSCI/src/slice/retrivePNRSlice";
import { airAwardBookingReset } from "./OSCI/src/slice/airAwardBookingSlice";
import { airAwardPriceReset } from "./OSCI/src/slice/airAwardPriceSlice";
import { airAwardTstReset } from "./OSCI/src/slice/airAwardTstSlice";
import { airAwardReviewPnrReset, savePageId } from "./OSCI/src/slice/airAwardReviewPnrSlice";
import { resetJourneyDetails } from "./DAPI/src/slice/checkin/checkinSlice";
import { resetAddPassengerDetails } from "./DAPI/src/slice/checkin/addPassengerSlice";
const sagaMiddleWare = createSagaMiddleware();
const persistConfigOSCI = {
  key: "osci",
  storage: sessionStorage,
  whitelist: ["profile", "airAwardPnr", "airAwardReviewPnrRM", "airAwardReviewPnrCancel", "flightInfo", "retrivePNRData", "airAwardBooking", "airAwardPrice", "airAwardTst", "airAwardReviewPnr", "redemption", "transferMiles"]
};
const persistConfigDAPI = {
  key: "dapi",
  storage: sessionStorage,
  whitelist: ["checkin", "selectPassenger"]
};
const persistedReducerOsci = persistReducer(persistConfigOSCI, osciRootReducer);
const persistedReducerDapi = persistReducer(persistConfigDAPI, dapiRootReducer);
const mainReducer = combineReducers({
  osciReducer: persistedReducerOsci,
  dapiReducer: persistedReducerDapi
});
export const store = configureStore({
  reducer: mainReducer,
  middleware: getDefaultMiddleWare => getDefaultMiddleWare({
    serializableCheck: {
      ignoredActions: [FETCH_DATA_CONSTANT, PURGE, FLUSH]
    }
  }).concat(sagaMiddleWare)
});
const saga = () => {
  return function* getMainSaga() {
    yield all([call(osciRootSaga), call(dapiRootSaga)]);
  };
};
sagaMiddleWare.run(saga());
export const persistor = persistStore(store);
export const resetPersistStore = async () => {
  store.dispatch(airAwardPnrReset());
  store.dispatch(airAwardReviewPnrRMReset());
  store.dispatch(airAwardReviewPnrCancelReset());
  store.dispatch(retrivePNRReset());
  store.dispatch(airAwardBookingReset());
  store.dispatch(airAwardPriceReset());
  store.dispatch(airAwardTstReset());
  store.dispatch(airAwardReviewPnrReset());
  store.dispatch(savePageId(""));
  sessionStorage.removeItem("persist:osci");
};
export const resetDapiPersistStore = async allowedArr => {
  if ((allowedArr === null || allowedArr === void 0 ? void 0 : allowedArr.length) > 0) {
    const allowedArray = [...allowedArr, "_persist"];
    const sessionData = await sessionStorage.getItem("persist:dapi");
    if (sessionData) {
      try {
        const temp1 = JSON.parse(sessionData);
        const temp2 = JSON.parse(JSON.stringify(temp1));
        const filteredSessionData = {};
        allowedArray === null || allowedArray === void 0 || allowedArray.forEach(x => {
          filteredSessionData[x] = temp2[x];
        });
        store.dispatch(resetAddPassengerDetails());
        sessionStorage.setItem("persist:dapi", JSON.stringify(filteredSessionData));
      } catch {}
    }
  } else {
    store.dispatch(resetJourneyDetails());
    await persistor.purge();
  }
};