import { call, put, takeEvery } from "redux-saga/effects";
import { airAwardPnrRequest, airAwardPnrSuccess, airAwardPnrFailure } from "../slice/airAwardPnrSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.AIR_AWARD_PNR_API;
const fetchAirAwardPnrDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  let response;
  if (!(payload !== null && payload !== void 0 && payload.isStarAlliance)) {
    response = await api.post(API_URL, payload, headerData);
  } else {
    let starURL = (config === null || config === void 0 ? void 0 : config.AIR_AWARD_PNR_API) + "?starAlliance=true";
    response = await api.post(starURL, payload, headerData);
  }
  return response;
};
function* fetchAirAwardPnr(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardPnrDetail, payload);
    yield put(airAwardPnrSuccess(response));
  } catch (error) {
    yield put(airAwardPnrFailure(error === null || error === void 0 ? void 0 : error.response));
  }
}
export function* watchAirAwardPnr() {
  yield takeEvery(airAwardPnrRequest.type, fetchAirAwardPnr);
}