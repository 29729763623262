import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  savedMyThaiCategory: {},
  isLoading: false,
  hasData: false,
  error: "",
  formStatus: {
    status: false,
    message: ""
  },
  ropError: "",
  ropHasData: false,
  successRopData: "",
  isCRMShowFormEnabled: false
};
const myThaiCRMFormSlice = createSlice({
  name: "myThaiCRMForm",
  initialState,
  reducers: {
    savedFormCategoryRequest: (state, action) => {
      state.savedMyThaiCategory = action.payload;
    },
    clearsavedFormCategoryRequest: (state, action) => {
      state.savedMyThaiCategory = null;
    },
    submitFormRequest: (state, action) => {
      state.isLoading = true;
    },
    submitFormSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successData = action.payload;
      if (state.successData.message === "Case Created") {
        state.formStatus.message = "Case Created";
        state.formStatus.status = true;
        sessionStorage.setItem("email", action.payload.email);
      }
      // state.codeStatus = "success";
    },
    submitFormFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    clearFormSuccessResponse: state => {
      state.formStatus.message = "";
      state.formStatus.status = false;
    },
    setCRMShowForm: (state, action) => {
      state.isCRMShowFormEnabled = action.payload;
    },
    submitRopFormRequest: (state, action) => {
      state.isLoading = true;
    },
    submitRopFormSuccess: (state, action) => {
      state.isLoading = false;
      state.ropError = "";
      state.ropHasData = true;
      state.successRopData = action.payload;
    },
    submitRopFormFailure: (state, action) => {
      state.isLoading = false;
      state.ropError = action.payload;
      state.ropHasData = false;
      state.successRopData = "";
    }
  }
});
export const {
  savedFormCategoryRequest,
  clearsavedFormCategoryRequest,
  submitFormRequest,
  submitFormFailure,
  submitFormSuccess,
  submitRopFormRequest,
  submitRopFormFailure,
  submitRopFormSuccess,
  clearFormSuccessResponse,
  setCRMShowForm
} = myThaiCRMFormSlice.actions;
export default myThaiCRMFormSlice.reducer;