import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  memberId: null,
  feedbackType: null,
  description: null,
  isThaiServicesFeedback: null,
  isParticularFlight: null,
  customerContactNo: null,
  countryCode: null,
  customerEmail: null,
  reviewSummary: [],
  successResponse: {},
  isLoading: false,
  isError: false,
  error: false
};
const helpFaqSlice = createSlice({
  name: "helpFaq",
  initialState,
  reducers: {
    helpFaqRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    helpFaqSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successResponse = action.payload;
    },
    helpFaqFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    },
    helpFaqInputSave: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    helpFaqClear: state => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    }
  }
});
export const {
  helpFaqRequest,
  helpFaqSuccess,
  helpFaqFailure,
  helpFaqInputSave,
  helpFaqClear
} = helpFaqSlice.actions;
export default helpFaqSlice.reducer;