import api from "../../../config/api";
import { call, put, takeEvery } from "redux-saga/effects";
import cmsApi from "../../../config/cmsApi";
import { config } from "../../../config/global";
export const FETCH_DATA_CONSTANT = "FETCH_DATA";
function* fetchData(action) {
  const configData = {
    engine: action !== null && action !== void 0 && action.isTeamsite ? "teamsite" : action !== null && action !== void 0 && action.isAemContentAuthored ? "aemContentAuthored" : action !== null && action !== void 0 && action.isAemConfiguration ? "aemConfigured" : action !== null && action !== void 0 && action.isAemServlet ? "aemServlet" : ""
  };
  try {
    const response = yield call(action !== null && action !== void 0 && action.isTeamsite || action !== null && action !== void 0 && action.isAemContentAuthored || action !== null && action !== void 0 && action.isAemConfiguration || action !== null && action !== void 0 && action.isAemServlet ? cmsApi.get : api.get, action.url, configData);
    yield put(action.successAction(response.data));
  } catch (error) {
    yield put(action.errorAction(error));
  }
}
export function* watchFetchData() {
  yield takeEvery(config.FETCH_DATA_CONSTANT, fetchData);
}
export default watchFetchData;