import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getMyThaiMyReqPending, getMyThaiMyReqSuccess, getMyThaiMyReqFailure } from "../slice/myThaiMyRequestsSlice";
import { config } from "../../../config/global";
const MY_THAI_MY_REQUEST_URL = config.MY_THAI_MY_REQUEST_API;
const fetchMyThaiMyReqData = async () => {
  let headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    isGenericErr: true,
    loader: true
  };
  const response = await api.get(MY_THAI_MY_REQUEST_URL, headerData);
  return response;
};
function* getMyThaiMyReqData() {
  try {
    const response = yield call(fetchMyThaiMyReqData);
    yield put(getMyThaiMyReqSuccess(response.data));
  } catch (error) {
    yield put(getMyThaiMyReqFailure(error));
  }
}
export function* watchMyThaiMyReqData() {
  yield takeLatest(getMyThaiMyReqPending.type, getMyThaiMyReqData);
}