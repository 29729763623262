import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { logoutRequest, getLogOutSuccess, getLogOutFailure, crmLogoutRequest } from "../slice/logOutSlice";
import { config } from "../../../config/global";
const fetchLogoutData = async () => {
  const LOGOUT_API_URL = "profile/user/logout";
  const response = await api.post(LOGOUT_API_URL);
  return response;
};
const fetchCRMLogoutData = async () => {
  const response = await api.post(config.CRM_LOGOUT);
  return response;
};
function* getLogOutData() {
  try {
    const response = yield call(fetchLogoutData);
    localStorage.removeItem("recentFromAirport");
    localStorage.removeItem("recentToAirport");
    localStorage.removeItem("tripStatus");
    yield put(getLogOutSuccess(response.data));
  } catch (error) {
    yield put(getLogOutFailure(error.message));
  }
}
function* getCRMLogOutData() {
  try {
    const response = yield call(fetchCRMLogoutData);
    yield put(getLogOutSuccess(response.data));
  } catch (error) {
    yield put(getLogOutFailure(error.message));
  }
}
export function* watchCRMLogOutData() {
  yield takeLatest(crmLogoutRequest.type, getCRMLogOutData);
}
export function* watchLogOutData() {
  yield takeLatest(logoutRequest.type, getLogOutData);
}