import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  extendExpiryMilesJsonError: {},
  extendExpiryMilesJsonData: {},
  isloadingReview: false,
  expiryMilesReviewData: {},
  expiryMilesReviewError: {},
  isLoadingPaymentInit: false,
  extendMilesPaymentInitInfo: {},
  isErrorPaymentInit: false,
  errorPaymentInit: "",
  isLoadingReceiptDownload: false,
  isErrorReceiptDownload: false,
  errorReceiptDownload: {},
  receiptData: {},
  downloadData: {},
  isBillingFailed: false
};
const fetchExtendExpiryMileSlice = createSlice({
  name: "extendExpiryMiles",
  initialState,
  reducers: {
    getExtendExpiryMilesJsonSuccess: (state, action) => {
      state.isLoading = false;
      state.extendExpiryMilesJsonError = {};
      state.extendExpiryMilesJsonData = action.payload;
    },
    getExtendExpiryMilesJsonFailure: (state, action) => {
      state.extendExpiryMilesJsonError = action.payload;
      state.extendExpiryMilesJsonData = {};
      state.isLoading = false;
    },
    setBillingFailed: (state, action) => {
      state.isBillingFailed = action.payload;
    },
    getExpiryMilesReviewPending: (state, action) => {
      state.isloadingReview = true;
      state.getDataSuccess = false;
      state.expiryMilesReviewError = {};
      state.expiryMilesReviewData = {};
    },
    getExpiryMilesReviewFailure: (state, action) => {
      state.isloadingReview = false;
      state.getDataSuccess = false;
      state.expiryMilesReviewError = action.payload;
      state.expiryMilesReviewData = {};
    },
    getExpiryMilesReviewSuccess: (state, action) => {
      state.isloadingReview = false;
      state.expiryMilesReviewData = action.payload;
      state.expiryMilesReviewError = {};
      state.getDataSuccess = true;
    },
    extendMilesPaymentInitRequest: (state, action) => {
      state.isLoadingPaymentInit = true;
      state.extendMilesPaymentInitInfo = {};
      state.isErrorPaymentInit = false;
      state.errorPaymentInit = "";
    },
    extendMilesPaymentInitSuccess: (state, action) => {
      state.isLoadingPaymentInit = false;
      state.hasData = true;
      state.extendMilesPaymentInitInfo = action.payload;
      state.errorPaymentInit = "";
    },
    extendMilesPaymentInitFailure: (state, action) => {
      state.isLoadingPaymentInit = false;
      state.isErrorPaymentInit = true;
      state.errorPaymentInit = action.payload;
      state.extendMilesPaymentInitInfo = {};
    },
    resetExtendMilesPaymentInit: (state, action) => {
      state.isLoadingPaymentInit = false;
      state.extendMilesPaymentInitInfo = {};
      state.isErrorPaymentInit = false;
      state.errorPaymentInit = "";
    },
    getExtendMilesReceiptDownloadRequest: (state, action) => {
      state.isLoadingReceiptDownload = true;
      state.downloadData = action.payload;
      state.receiptData = {};
      state.errorReceiptDownload = {};
    },
    getExtendMilesReceiptDownloadSuccess: (state, action) => {
      state.isLoadingReceiptDownload = false;
      state.hasData = true;
      state.receiptData = action.payload;
      state.errorReceiptDownload = "";
    },
    getExtendMilesReceiptDownloadFailed: (state, action) => {
      state.isLoadingReceiptDownload = false;
      state.isErrorReceiptDownload = true;
      state.errorReceiptDownload = action.payload;
      state.receiptData = {};
    }
  }
});
export const {
  getExtendExpiryMilesJsonSuccess,
  getExtendExpiryMilesJsonFailure,
  getExpiryMilesReviewPending,
  getExpiryMilesReviewSuccess,
  getExpiryMilesReviewFailure,
  extendMilesPaymentInitRequest,
  extendMilesPaymentInitSuccess,
  extendMilesPaymentInitFailure,
  resetExtendMilesPaymentInit,
  getExtendMilesReceiptDownloadRequest,
  getExtendMilesReceiptDownloadSuccess,
  getExtendMilesReceiptDownloadFailed,
  setBillingFailed
} = fetchExtendExpiryMileSlice.actions;
export default fetchExtendExpiryMileSlice.reducer;