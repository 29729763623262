export const config = {
  AIRPORT_LIST: "common/airport/list",
  CMS_BANNER_CAROUSEL_HOME: "/homepage/bannercarousel/jcr:content/root/container/container/bannercarousel.model.json",
  ROP_URL: "./json/rop.json",
  COUNTRY_LIST: "common/country/getAllRegionList",
  BOARDING_PASS: "/checkin/boardingPass",
  CHECKIN_BOARDING_PASS: "/check-in/boarding-passes",
  GEOLOCATION: "common/geolocation",
  TEAMSITE_ROH_LIST: "./json/roh.json",
  BOOKING_API: "refx/booking",
  MULTI_API: "multi/booking",
  ROP: "/ropData.json",
  PROMO_API: "validatePromoCode",
  MANAGE_BOOKING_API: "managebooking",
  CHECKIN_API: "checkin",
  FLIGHTSTATUS_API: "flightstatus",
  UNLOCK_ACCOUNT_API: "profile/user/resetPinSubmit",
  TEAMSITE_PRIVILEGE_LIST: "./json/privilege.json",
  TEAMSITE_HEADER_ENDPOINT: "/headerDetails.json",
  //CMS_HEADER_ENDPOINT: "/homepage/header/jcr:content/root/container/container/header.model.json",
  //TEAMSITE_FOOTER_ENDPOINT: "/footer.json",
  CMS_FOOTER_ENDPOINT: "/homepage/footer/jcr:content/root/container/container/footer.model.json",
  // REACT_APP_TG_UAT_URL : 'http://13.215.223.124:8080/'
  CMS_ROPMEMBERJOIN_API: "/joinnow.json",
  //TEAMSITE_ROPMEMBERJOIN_API: "/ropMemberJoin.json",
  //TEAMSITE_SAWASDEECARD_ENDPOINT: "/sawasdeeMag.json",
  CMS_SAWASDEECARD_ENDPOINT: "/sawasdee.json",
  TEAMSITE_LIFESTYLE_WORLDAWAITS_API: "/advertisementCard.json",
  // TEAMSITE_LIFESTYLE_DESTINATION_CONTENT_API: "/advertisementCarousel.json",
  TEAMSITE_LIFESTYLE_DESTINATION_CONTENT_API: "/homepage/lifestyle/jcr:content/root/container/container/lifestyle.model.json",
  TEAMSITE_PROMOTIONS_API: "/promotions.json",
  PARTNER_PRIVILEGES_API: "/partners.json",
  CMS_PARTNERS_API: "/homepage/partner/jcr:content/root/container/container/partners.model.json",
  TEAMSITE_APPSTORE_API: "/appStore.json",
  //TEAMSITE_NOTICE_BANNER_URL: "/bannerData.json",
  CMS_NOTICE_BANNER_URL: "/banner/notification.json",
  //TEAMSITE_MODAL_URL: "/modalData.json",
  CMS_MODAL_URL: "/modal/notification.json",
  //TEAMSITE_MORE_SERVICES_API: "./moreServices.json",
  CMS_MORE_SERVICES_API: "/homepage/moreservices/_jcr_content/root/container/container/moreservices.model.json",
  TEAMSITE_GLOBAL_SEARCH_API: "./globalSearch.json",
  TEAMSITE_SEARCH: "/search/search.page",
  TEAMSITE_PROMOTION: "en_TH/rop/Promotion/promotion.page",
  TEAMSITE_ADDITIONAL_SERVICES_API: "./additionalServices.json",
  TEAMSITE_UPCOMING_TRIPS_API: "/trip/retrieve-trips?category=upcoming",
  TEAMSITE_RETRIEVE_PNR_API: "/trip/retrieve-pnr?isUpcoming=true",
  TEAMSITE_NOMINEE_TERMS: "/nomineeTerms.json",
  TEAMSITE_DELETE_ACCOUNT_TERMS: "/deleteAccountTerms.json",
  TEAMSITE_EXTRA_BAGGAGE_DETAILS: "/extraBaggageDetails.json",
  TEAMSITE_SEAT_PREFERENCE_DETAILS: "/preferredSeatDetails.json",
  FETCH_PROFILE: "profile/fetchProfile",
  FLIGHT_INFO_API: "/airaward-flights/get-flight-info",
  FETCH_NIOMINEE: "/loyalty/nominee",
  TEAMSITE_INSPIRATION_DEALS_DISCOVER_API: "/inspirationDeals.json",
  TEAMSITE_INSPIRATION_DEALS_DISCOVER_CONTENT_API: "/inspirationDealsCarousel.json",
  FLIGHT_FARE_API: "/airaward-flights/get-fare",
  TEAMSITE_SOCIAL_PLATFORMS: "/socialMedia.json",
  TEAMSITE_BAGGAGE_ALLOWANCE: "/baggageAllowanceOSCI.json",
  MANAGE_MILES: "/manageMilesData.json",
  NEWSLETTER_PROMOTIONS_FROM_OPTIONS_API: "/newsletterPreferenceList.json",
  PROFILE_CONTACT_UPDATE: "profile/user/update",
  FETCH_MAIN_MEMBER_PROFILE: "profile/getMainMemberProfile",
  FETCH_CRM_MEMBER_PROFILE: "mythai/profile",
  FETCH_MAIN_MEMBER_MILES: "loyalty/miles",
  FLIGHT_SCHEDULE: "flight-timetable",
  AIR_AWARD_BOOKING_API: "/airaward/booking",
  AIR_AWARD_PNR_API: "/airaward/pnr",
  AIR_AWARD_PRICE_API: "/airaward/price",
  AIR_AWARD_TST_API: "/airaward/tst",
  RETRIVE_PNR: "/airaward-review/pnr/review",
  CANCEL_BOOKING_PNR: "/airaward-review/booking/cancel",
  AIR_AWARD_REVIEW_PNR_API: "/airaward-review/pnr/rf",
  AIR_AWARD_REVIEW_PNR_RM_API: "/airaward-review/pnr/rm",
  AIR_AWARD_REVIEW_PNR_CANCEL_API: "/airaward-review/pnr/cancel",
  FETCH_MY_LIFESTYLE: "loyalty/lifestyle",
  CRM_FETCH_PROFILE: "mythai/profile",
  DELETE_TRIP_CARD: "/trip/delete",
  AIR_AWARD_AIRPORT_API: "/airaward-flights/airports",
  PROFILE_PERSONAL_INFO_UPDATE: "/profile/user/update",
  CRM_LOGIN_OTP: "mythai/login/otp/submit",
  SOCIAL_MEDIA_AUTH: "/mythai/social-media/auth",
  SOCIAL_MEDIA_SIGN_UP: "mythai/social-media/signup",
  FETCH_DATA_CONSTANT: "FETCH_DATA",
  CRM_PROFILE_UPDATE: "mythai/profile/update",
  MILEAGE_STATEMENT: "loyalty/miles/info",
  MILEAGE_AWARD_LIST: "loyalty/member-award/list",
  MILEAGE_AWARD_DETAIL: "loyalty/member-award/detail",
  MILEAGE_REPORT: "loyalty/miles/report",
  E_RECEIPT_API: "ereceipt/printEreceipt",
  UPDATE_SPONSOR_API: "ereceipt/updateSponsor",
  ROP_CHANGE_PASSWORD_API: "/profile/change-pin/otp",
  ROP_CHANGE_PASSWORD_OTP_RESEND_API: "/profile/change-pin/otp/resend",
  ROP_CHANGE_PASSWORD_SUBMIT_API: "/profile/change-pin/otp/submit",
  CRM_RESEND_OTP: "/mythai/login/otp/resend",
  CRM_LOGOUT: "mythai/logout",
  IOS_APP_QR: "/assets/app_download_qr/ios_app_download.png",
  ANDROID_APP_QR: "/assets/app_download_qr/android_app_download.png",
  AIR_AWARD_PAYMENT_INIT: "/airaward-review/payment",
  TEAMSITE_MY_TRIPS_UPCOMING_TRIPS_API: "/trip/retrieve-trips",
  MY_TRIPS_RETRIEVE_API: "/trip/retrieve-pnr",
  BACK_TRACKING_AIRPORT_API: "/airaward-flights/airports/backtracking",
  TEAMSITE_HELP_ENDPOINT: "/helpMenu.json",
  ADD_MY_TRIP_API: "/trip/add",
  TEAMSITE_ERECEIPT_TERMS: "/ereceipt-termscondition.json",
  CRM_NATIONALITY_API: "/custom_config/country-city-state/en/crmnationality.json",
  MY_TRIPS_CHECK_IN_API: "check-in/journeys",
  MY_TRIPS_CHECK_IN_CPR_API: "checkin/cpr",
  COUNTRY_DATA: "/custom_config/country-city-state/en/country-nationality-code-flag.json",
  CITY_DATA: "/custom_config/country-city-state/en/city_state_mapping.json",
  STATE_DATA: "/custom_config/country-city-state/en/state_country_mapping.json",
  PAID_NOMINEE_SEND_OTP: "loyalty/nominee/paidnominee/otp",
  PAID_NOMINEE_RESEND_OTP: "loyalty/nominee/paidnominee/resend-otp",
  PAID_NOMINEE_SUBMIT_OTP: "loyalty/nominee/paidnominee/submit-otp",
  FETCH_ELIGIBILITY: "loyalty/membertier/award/eligibility",
  TEAMSITE_MEMBER_STATUS_TERMS: "/memberStatusCalculator.json",
  TEAMSITE_EXTEND_EXPIRY_MILES: "/extendExpiryMile.json",
  CRM_COUNTRYCODE_JSON: "/custom_config/country-city-state/en/crmcountryphonecode.json",
  CRM_NEWSLETTERPREFERENCE_JSON: "/custom_config/country-city-state/en/newsletterPreferenceList.json",
  CRM_RESIDENCE_JSON: "/custom_config/country-city-state/en/crmresidence.json",
  MEMBER_TIER_CALCULATOR: "/loyalty/membertier/calculate",
  TEAMSITE_GIFT_MILES_TERMS: "/giftmilesTerms.json",
  FETCH_GIFT_MILES_DROPDOWN: "/loyalty/mileage-purchase/listMileage",
  CLAIM_MISSING_MILES_API: "/loyalty/retro-claim/star-alliance-missing-miles",
  CLAIM_MISSING_STAR_API: "/loyalty/retro-claim/tg-missing-miles",
  CLAIM_TERMS_CONDITION: "/claimMissing-termscondition.json",
  CLAIM_BOOKING_CLASS_API: "/claim-booking-classes.json",
  CLAIM_AIRLINE_API: "/common/airline/list",
  MEMBER_AWARD: "/loyalty/membertier/award/eligibility",
  MY_THAI_MY_REQUEST_API: "https://tgosci-sit.com/mythai/my-requests",
  MILEAGE_PURCHASE_TERMS: "/mileagePurchaseTerms.json",
  REVIEW_SUMMARY_GIFT_MILES: "/loyalty/miles/gift/review",
  PROCESS_PAYMENT_GIFT_MILES: "/loyalty/mileage-purchase/processPayment",
  GIFT_MILES_DOWNLOAD_RECEIPT: "/loyalty/mileage-purchase/paymentReceipt",
  TEAMSITE_TRANSFER_MILES_TERMS: "/transferMiles-terms.json",
  TRANSFER_MILES_REVIEW: "/loyalty/miles/transfer/review",
  TRANSFER_MILES_PAYMENT_INIT: "/loyalty/miles/transfer/payment-init",
  TRANSFER_MILES_DOWNLOAD_RECEIPT: "loyalty/miles/transfer/receipt",
  EXTEND_MILES_PAYMENT_INIT: "/loyalty/miles/extend/paymentInit",
  EXTEND_MILES_DOWNLOAD_RECEIPT: "/loyalty/miles/extend/paymentReceipt",
  EARNED_MILES_API: "/loyalty/miles-calculator/earned-miles",
  REDEEM_MILES_API: "/loyalty/miles-calculator/redeem-miles",
  STAR_ALLIANCE_AIR_LINE_LIST_API: "/common/airline/list",
  EXCESS_BAGGAGE_API: "/loyalty/miles-calculator/excess-baggage-awards",
  UPGRADE_SEAT_API: "/loyalty/miles-calculator/upgrade-seat-awards",
  BAGGAGE_ALLOWANCE: "common/more-services/excess-baggage-info",
  SEAT_PREFERENCE: "common/more-services/preferred-seat",
  BARCODE_SCANNING: "common/barcode/retrieve",
  BAGGAGE_SEARCH: "loyalty/baggage/bagJourney",
  MILES_SEARCH: "loyalty/miles-calculator/air-awards-miles",
  //STAR ALLIANCE REDEMPTION
  STAR_ALLIANCE_AIRPORT_LIST: "/airaward-flights/star-airports/route?multiCity=false",
  STAR_ALLIANCE_AIRPORT_LIST_MULTI_CITY: "/airaward-flights/star-airports/route?multiCity=true",
  STAR_ALLIANCE_SEARCH: "airaward-flights/star-get-flight-info",
  DOWNLOAD_AIRPORT_BOOKING_PDF: "airaward-review/report/payment-summary",
  CRM_CHANGE_PASSWORD: "/mythai/profile/changePassword/otp",
  CRM_CHANGE_PASSWORD_OTP_RESEND: "/mythai/profile/changePassword/otp/resend",
  CRM_CHANGE_PASSWORD_SUBMIT: "/mythai/profile/changePassword/otp/submit",
  MILES_BEYOND_BANGKOK: "pdf/Star_Alliance_Awards_Chart_2019-One_way.pdf",
  // DAPI
  ADD_PAX_CHECK_IN_API: "/check-in/passengers/merge",
  DAPI_CANCEL_CHECKIN: "/check-in/cancel",
  AIR_AWARD_CONFIRMATION: "/airaward-review/pnr/confirmation/summary",
  DAPI_CANCEL_RETRIEV_CHECKIN: "check-in/journey",
  REMOVE_SELECT_PASSENGER_CHECKIN: "/check-in/passengers",
  SELECT_PASSENGER_REGULATORY_CHECKIN: "/check-in/passengers/regulatory-details",
  UPDATE_PASSENGER_DETAILS: "/check-in/passengers/regulatory-details/upsert",
  FLIGHT_STATUS_NUMBER: "flights/flight-status/by-number",
  PASSENGER_DETAILS_FFP: "/custom_config/frequent_flyer_program/frequent_flyer_pgmlist.json",
  PASSENGER_DETAILS_COUNTRY: "/custom_config/country-city-state/dapi/en/dapicountryNationalityFlag.json",
  EXPIRING_MILES_REVIEW: "/loyalty/miles/extendExpiringMiles/review",
  HELP_FAQ: "mythai/feedback",
  MYTHAI_CRM_FORM_FLIGTH_SUPPORT: "/mythai/flight-support",
  MYTHAI_ROP_FORM_FLIGTH_SUPPORT: "/mythai/rop-services",
  CHECKIN_AUTO_SEAT_SELECTION: "/ancillaries/checkin/seat",
  SEAT_MAP_CHECK_IN_API: "/ancillaries/checkin/seatmap",
  CRM_SERVICE_REQUEST: "mythai/special-service-request"
};