import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  changePasswordResendOTPDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending"
};
const changePasswordOTPResendSlice = createSlice({
  name: "resendSection",
  initialState,
  reducers: {
    changePasswordResendOTPPending: state => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    changePasswordResendOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.changePasswordResendOTPDetails = action.payload;
      state.codeStatus = "success";
    },
    changePasswordResendOTPFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
      state.hasData = false;
    },
    changePasswordResendOTPClear: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.changePasswordResendOTPDetails = [];
      state.codeStatus = "pending";
    }
  }
});
export const {
  changePasswordResendOTPPending,
  changePasswordResendOTPSuccess,
  changePasswordResendOTPFail,
  changePasswordResendOTPClear
} = changePasswordOTPResendSlice.actions;
export default changePasswordOTPResendSlice.reducer;