import { call, put, takeLatest } from "redux-saga/effects";
import { checkinBoardingPassDataRequest, getCheckinBoardingPassSuccess, getCheckinBoardingPassFailure } from "../../slice/checkin/checkinBoardingPassSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const headerData = {
  mode: "no-cors",
  headers: {
    accesstoken: localStorage.getItem("accesstoken"),
    "Content-Type": "application/json"
  },
  loader: true
};
const fetchBoardingPassData = async payload => {
  const BOARDING_PASS_API_URL = config.CHECKIN_BOARDING_PASS;
  let payloadData = {
    journeyId: payload
  };
  const response = await api.post(BOARDING_PASS_API_URL, payloadData, headerData);
  return response;
};
function* getCheckinBoardingPassData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchBoardingPassData, payload);
    yield put(getCheckinBoardingPassSuccess(response === null || response === void 0 ? void 0 : response.data));
  } catch (error) {
    yield put(getCheckinBoardingPassFailure(error === null || error === void 0 ? void 0 : error.response));
  }
}
export function* watchCheckinBoardingPassData() {
  yield takeLatest(checkinBoardingPassDataRequest.type, getCheckinBoardingPassData);
}