import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  getDataSuccess: false,
  myThaiMyReqError: {},
  myThaiMyReqData: {}
};
const myThaiMyRequestsSlice = createSlice({
  name: "myThaiMyRequests",
  initialState,
  reducers: {
    getMyThaiMyReqPending: state => {
      state.isLoading = true;
      state.getDataSuccess = false;
      state.myThaiMyReqError = {};
      state.myThaiMyReqData = {};
    },
    getMyThaiMyReqFailure: (state, action) => {
      state.isLoading = false;
      state.getDataSuccess = false;
      state.myThaiMyReqError = action.payload;
      state.myThaiMyReqData = {};
    },
    getMyThaiMyReqSuccess: (state, action) => {
      state.isLoading = false;
      state.myThaiMyReqData = action.payload;
      state.getDataSuccess = true;
      state.myThaiMyReqError = {};
    }
  }
});
export const {
  getMyThaiMyReqPending,
  getMyThaiMyReqSuccess,
  getMyThaiMyReqFailure
} = myThaiMyRequestsSlice.actions;
export default myThaiMyRequestsSlice.reducer;