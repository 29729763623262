import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  flightNumberDetail: null,
  flightNumberInputs: null,
  selectedRecentSearchData: null,
  flightRoute: null,
  isLoading: false,
  isError: false,
  error: false
};
const FlightStatusSlice = createSlice({
  name: "flightStatus",
  initialState,
  reducers: {
    flightStatusByNumberRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    flightStatusByNumberSuccess: (state, action) => {
      state.flightNumberDetail = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    flightStatusByNumberFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    },
    flightStatusNumberInputSave: (state, action) => {
      state.flightNumberInputs = action.payload;
    },
    flightStatusNumberClear: state => {
      state.flightNumberDetail = null;
      state.flightNumberInputs = null;
      state.flightRoute = null;
      state.isLoading = false;
      state.error = false;
      state.isError = false;
    },
    resetFlightNumberDetail: state => {
      state.flightNumberDetail = null;
    },
    updateFlightSelectedCardData: (state, action) => {
      const {
        cardData
      } = action.payload;
      state.selectedRecentSearchData = cardData;
    }
  }
});
export const {
  flightStatusByNumberRequest,
  flightStatusByNumberSuccess,
  flightStatusByNumberFailure,
  flightStatusNumberInputSave,
  flightStatusNumberClear,
  updateFlightSelectedCardData,
  resetFlightNumberDetail
} = FlightStatusSlice.actions;
export default FlightStatusSlice.reducer;