import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  crmResendOTPDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending"
};
const crmResendOTPSlice = createSlice({
  name: "crmResendSection",
  initialState,
  reducers: {
    crmResendOTPPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    crmResendOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmResendOTPDetails = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem("refNo", action.payload.otpRefKey);
    },
    crmResendOTPFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
      state.hasData = false;
    },
    crmResendOTPClear: state => {
      console.log("test crm otp clear", state);
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.crmResendOTPDetails = {};
      state.codeStatus = "pending";
    }
  }
});
export const {
  crmResendOTPPending,
  crmResendOTPSuccess,
  crmResendOTPFail,
  crmResendOTPClear
} = crmResendOTPSlice.actions;
export default crmResendOTPSlice.reducer;