import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  error: "",
  hasData: false,
  otpDetails: {},
  codeStatus: "",
  otpStatus: {},
  requestLoading: false,
  statusMessage: false,
  codeExpired: false,
  ropIdValidation: false
};
const addNomineeSlice = createSlice({
  name: "addNominee",
  initialState,
  reducers: {
    sendNomineeOTPRequest: (state, action) => {
      state.isLoading = true;
      state.requestLoading = true;
      state.codeStatus = "pending";
    },
    sendNomineeOTPSuccess: (state, action) => {
      var _action$payload;
      state.isLoading = false;
      state.requestLoading = false;
      state.error = "";
      state.hasData = true;
      state.otpDetails = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem("refNumber", JSON.stringify(action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.otpRefKey));
      state.ropIdValidation = true;
    },
    sendNomineeOTPFail: (state, action) => {
      state.isLoading = false;
      state.requestLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
      state.ropIdValidation = false;
    },
    resendNomineeOTPRequest: state => {
      state.isLoading = true;
      state.requestLoading = true;
      state.codeStatus = "pending";
    },
    resendNomineeOTPSuccess: (state, action) => {
      var _action$payload2;
      state.isLoading = false;
      state.requestLoading = false;
      state.error = "";
      state.hasData = true;
      state.otpDetails = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem("refNumber", JSON.stringify(action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.otpRefKey));
    },
    resendNomineeOTPFail: (state, action) => {
      state.isLoading = false;
      state.requestLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
      state.hasData = false;
    },
    submitNomineeOTPRequest: state => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    submitNomineeOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.otpDetails = action.payload;
      state.otpStatus = action.payload;
      state.statusMessage = true;
    },
    submitNomineeOTPFail: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.error = action.payload;
      state.otpStatus = {};
    },
    resetNomineeOTPData: state => {
      state.error = "";
      state.otpDetails = [];
      state.hasData = false;
    },
    resetRopValidation: (state, action) => {
      state.ropIdValidation = action.payload;
      state.otpStatus = {};
    }
  }
});
export const {
  sendNomineeOTPRequest,
  sendNomineeOTPFail,
  sendNomineeOTPSuccess,
  resendNomineeOTPRequest,
  resendNomineeOTPSuccess,
  resendNomineeOTPFail,
  submitNomineeOTPRequest,
  submitNomineeOTPFail,
  submitNomineeOTPSuccess,
  resetNomineeOTPData,
  resetRopValidation
} = addNomineeSlice.actions;
export default addNomineeSlice.reducer;