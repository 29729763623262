import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  hasData: false,
  dropDowndata: {},
  termsConditionData: "",
  termsConditionError: "",
  reviewData: {},
  reviewSummary: [],
  giftMilesPaymentInitInfo: {},
  isError: false,
  error: ""
};
const giftMilesSlice = createSlice({
  name: "giftMilesSlice",
  initialState,
  reducers: {
    giftMilesTermsConditionRequest: state => {
      state.isLoading = true;
    },
    giftMilesTermsConditionSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.termsConditionData = action.payload;
      state.termsConditionError = "";
    },
    giftMilesTermsConditionFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsConditionError = action.payload;
    },
    getGiftMilesDropdownRequest: state => {
      state.isLoading = true;
    },
    getGiftMilesDropdownSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.dropDowndata = action.payload;
      state.error = "";
    },
    getGiftMilesDropdownFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    reviewSummaryRequest: (state, action) => {
      state.isLoading = true;
      state.reviewData = action.payload;
    },
    reviewSummarySuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.reviewSummary = action.payload;
      state.error = "";
    },
    reviewSummaryFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.response.data;
    },
    giftMilesPaymentInitRequest: (state, action) => {
      state.isLoading = true;
    },
    giftMilesPaymentInitSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.giftMilesPaymentInitInfo = action.payload;
      state.error = "";
    },
    giftMilesPaymentInitFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    getGiftMilesReceiptDownloadRequest: (state, action) => {
      state.isLoading = true;
      state.downloadData = action.payload;
    },
    getGiftMilesReceiptDownloadSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.receiptData = action.payload;
      state.error = "";
    },
    getGiftMilesReceiptDownloadFailed: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetGiftMiles: state => {
      state.isLoading = false;
      state.hasData = false;
      state.dropDowndata = {};
      state.termsConditionData = "";
      state.termsConditionError = "";
      state.reviewData = {};
      state.reviewSummary = [];
      state.giftMilesPaymentInitInfo = {};
      state.isError = false;
      state.error = "";
    },
    redirection: (state, action) => {
      state.isRedirect = false;
      state.reviewSummary = action.payload;
      state.isError = false;
      state.error = "";
    }
  }
});
export const {
  giftMilesTermsConditionRequest,
  giftMilesTermsConditionSuccess,
  giftMilesTermsConditionFailure,
  getGiftMilesDropdownRequest,
  getGiftMilesDropdownSuccess,
  getGiftMilesDropdownFailure,
  reviewSummaryRequest,
  reviewSummarySuccess,
  reviewSummaryFailure,
  giftMilesPaymentInitRequest,
  giftMilesPaymentInitSuccess,
  giftMilesPaymentInitFailure,
  resetGiftMiles,
  getGiftMilesReceiptDownloadRequest,
  getGiftMilesReceiptDownloadSuccess,
  getGiftMilesReceiptDownloadFailed,
  redirection
} = giftMilesSlice.actions;
export default giftMilesSlice.reducer;