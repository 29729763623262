import { call, put, takeEvery } from "redux-saga/effects";
import { crmChangePasswordResendOTPFail, crmChangePasswordResendOTPPending, crmChangePasswordResendOTPSuccess } from "../slice/crmChangePasswordOTPResendSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.CRM_CHANGE_PASSWORD_OTP_RESEND;
const resendOTPDataCRM = async payload => {
  const headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website"
    }
  };
  const response = await api.get(API_URL, headerData);
  sessionStorage.setItem("refNumber", response.data.otpRefKey);
  return response;
};
function* crmChangePasswordResendOTPSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(resendOTPDataCRM, payload);
    yield put(crmChangePasswordResendOTPSuccess(response.data));
  } catch (error) {
    yield put(crmChangePasswordResendOTPFail(error));
  }
}
export function* watchCRMChangePasswordResendOTP() {
  yield takeEvery(crmChangePasswordResendOTPPending.type, crmChangePasswordResendOTPSaga);
}