import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  hasData: false,
  searchData: {},
  error: "",
  isError: false
};
const searchByMilesSlice = createSlice({
  name: "searchByMilesSlice",
  initialState,
  reducers: {
    searchByMilesRequest: (state, action) => {
      state.isLoading = true;
    },
    searchByMilesSuccess: (state, action) => {
      state.isLoading = false;
      state.searchData = action.payload;
      state.isError = false;
      state.error = "";
    },
    searchByMilesFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    }
  }
});
export const {
  searchByMilesRequest,
  searchByMilesSuccess,
  searchByMilesFailure
} = searchByMilesSlice.actions;
export default searchByMilesSlice.reducer;