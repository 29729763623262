import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  seatMapData: {},
  isLoading: false,
  hasData: false,
  seatMapSuccess: false,
  error: ""
};
const fetchSeatMapSlice = createSlice({
  name: "seatMap",
  initialState,
  reducers: {
    seatMapRequest: (state, action) => {
      state.isLoading = true;
      state.seatMapSuccess = false;
      state.error = "";
    },
    seatMapSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.seatMapSuccess = true;
      state.seatMapDetails = action.payload;
    },
    seatMapFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data;
      state.seatMapSuccess = false;
      state.seatMapDetails = {};
    },
    resetSeatMapDetails: state => {
      state.isLoading = false;
      state.seatMapDetails = {};
      state.seatMapSuccess = false;
      state.hasData = false;
      state.error = "";
    },
    resetPaxStatus: state => {
      state.seatMapSuccess = false;
    }
  }
});
export const {
  seatMapRequest,
  seatMapSuccess,
  seatMapFailure,
  resetSeatMapDetails,
  resetPaxStatus
} = fetchSeatMapSlice.actions;
export default fetchSeatMapSlice.reducer;